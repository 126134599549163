<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <head-center></head-center>
            </nav>
        </div>
        <!--      内容          -->
        <div class="center-content">
            <div class="content-box">
                <el-row type="flex" justify="space-between" :gutter="20">
                    <el-col :span="24" @click="toDetail">
                        <el-card>
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item :to="{ path: '/square' }">选品广场</el-breadcrumb-item>
                                <el-breadcrumb-item>商品详情</el-breadcrumb-item>
                            </el-breadcrumb>
                            <div class="item-div flex-space-between" style="margin-top:20px;align-items: flex-start;">
                                <div class="img-box">
                                        <el-carousel trigger="click">
                                            <el-carousel-item v-for="item in goodDetail.showCommodityCover" :key="item">
                                                <img :src="item" >
                                            </el-carousel-item>
                                        </el-carousel>
                                </div>
                                <div class="right-div">
                                    <div class="t1">{{goodDetail.commodityName}}</div>
                                    <div>市场价：¥ {{goodDetail.guidancePrice}} </div>
                                    <div>更多优惠联系请联系客服<span class="price">¥ {{goodDetail.platformPrice}}</span></div>
                                    <div>联系客服：<span class="t2">{{goodDetail.agentStore.customerPhone}}</span></div>
                                    <div>产地：<span class="t3">{{goodDetail.origin}}</span><i class="el-icon-circle-check t4"></i></div>
                
                                    <div class="t5">{{goodDetail.manufacturer}}</div>
                                    <div>购买须知:<i class="v-html" v-html="goodDetail.instructions"></i></div>
                                    <div>商品介绍:<i class="v-html" v-html="goodDetail.introduce"></i></div>
                                    <div>售后服务:<i class="v-html" v-html="goodDetail.customerService"></i></div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
import { ShoppingAgent } from '../../../components/ShoppingAgent/commodity';
export default {
    name: "squareDetail",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    components: {
        countTo
    },
    
    data() {
         var ShoppingAgentTemop = new ShoppingAgent(this.TokenClient, this.Services.Shopping);
        return {
            ShoppingAgentDomain: ShoppingAgentTemop,
            value1: 5,
            num: 1,
            index: 1,
            id: this.$route.query.id, //列表页id
            goodDetail:{}
        }
    },
    mounted() {
        this.getManagerListData()
    },
    methods: {
        getManagerListData() {
            var _this = this;
            _this.ShoppingAgentDomain.AgentCommodityDetail(_this.id, function (data) {
                _this.goodDetail = data.data
                let paramslist = []
                    for (let j=0; j< JSON.parse(data.data.commodityCover).length; j++) {
                        paramslist.push(_this.Services.Shopping + JSON.parse(data.data.commodityCover)[j])
                      
                    }
                    data.data.showCommodityCover = paramslist
                    data.data.introduce = data.data.introduce.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                    data.data.instructions = data.data.instructions.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                    data.data.customerService = data.data.customerService.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto"')
            },
                function (error) {
                    console.log(error);
                }
            )
        }
    }
}
</script>

<style scoped lang="scss">

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/*content*/
.center-content {
    height: calc(100vh - 43px);
    overflow-y: auto;
    padding: 100px 15px 0;

    .content-box ::v-deep .el-row {
        margin-bottom: 20px;
    }

    .content-box ::v-deep .el-card {
        cursor: pointer;
    }

    .item-div {
        cursor: pointer;

        .img-box {
            width: 30%;

            // border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                transition: all 0.3s;
            }

            img:hover {
                transform: scale(1.1)
            }
        }

        .right-div {
            width: 65%;
            color: #828690;

            div {
                margin-bottom: 5px;
            }

            .t1 {
                font-size: 15px;
                font-weight: bold;
                color: #000000;
            }

            .t2 {
                text-decoration: underline // font-size: 12px;
            }

            .t4 {
                color: #2BC155 !important;
                margin-left: 5px;
            }

            .price {
                font-size: 20px;
                color: var(--baseColor);
                font-weight: bold;
                margin-left: 10px;
            }

            .t3 {
                font-size: 12px;
                color: #B1B1B1;
            }

            .t5 {
                margin-top: 10px;
            }

            .t6 {
                background-color: #e3f9e9;
                color: #2BC155;
                line-height: 1.5;
                border-radius: 1.25rem;
                font-size: 14px;
                font-weight: 600;
                padding: 4px 10px;
                border: 1px solid transparent;
                margin-right: 5px;
            }

            .specification {
                width: auto;
                color: var(--baseColor);

                div {
                    min-width: 100px;
                    height: 35px;
                    line-height: 35px;
                    padding: 0 15px;
                    border: 1px solid var(--baseColor);
                }

                div:nth-child(n) {
                    border-right: none;
                }

                div:first-child {
                    border-radius: 20px 0 0 20px;
                }

                div:last-child {
                    border-right: 1px solid var(--baseColor);
                    border-radius: 0 20px 20px 0;
                }

                div:hover {
                    background: var(--baseColor);
                    color: #fff;
                }

                .active {
                    background: var(--baseColor);
                    color: #fff;
                }
            }
        }
    }

    // .item-div:nth-child(2n) {
    //     margin-right: 0;
    // }
}

.elCard {
    box-shadow: none;
    margin-top: 25px;
}
</style>
